import React from "react";
import intl from 'react-intl-universal';
import moment from 'moment';
import { Container, Row, Col } from "reactstrap";
import { graphql } from 'gatsby';

class EventTemplate extends React.Component {
  render() {
    const locale = this.props.pathContext.locale;
    const event = this.props.pathContext.event;
    const place = event.place[locale];

    return (
      <Container>
        <Row>
          <Col>
            <h1>{place}</h1>
            <p className="date-text">
              {intl.get('date', { date: moment(event.dates.from).toDate() })}
            </p>
            <p dangerouslySetInnerHTML={{__html: event.description[locale]}}></p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EventTemplate

export const pageQuery = graphql`
  query EventPageQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
